import React from 'react';
import { Header } from './Header';
import SumaversiryHeader from './sumaversity/HeaderSumaversity';

interface LoggedInHeaderProps {}

export const LoggedInHeader: React.FC<LoggedInHeaderProps> = React.memo(() => {
  return (
    <Header>
      <SumaversiryHeader />
    </Header>
  );
});

LoggedInHeader.displayName = 'LoggedInHeader';
